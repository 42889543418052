<template>
	<div class="left-sidebar">
		<div class="sidebar-holder">
			<ul class="nav nav-list mainmenu">
				<!-- sidebar to mini Sidebar toggle -->

				<li class="nav-toggle">
					<button class="btn btn-nav-toggle text-primary"
						style="border: 1px solid #cfd8e3; color: #495b79 !important">
						<i class="fa fa-angle-double-left toggle-left pull-right"></i>
					</button>
				</li>
				<li v-if="loginusercheck(152)">
					<router-link to="/dashboard" @click.native="remove">
						<i class="fa fa-dashboard"></i>
						<span class="hidden-minibar">Outstandings & Reports </span>
					</router-link>
				</li>
				<li v-if="loginusercheck(99)">
					<router-link to="/directpayment" @click.native="remove"><i class="fa fa-paypal"></i>
						<span class="hidden-minibar">Direct Payment<span class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
				</li>
				<li v-if="loginusercheck(129)">
					<router-link to="/loanfollowed" @click.native="remove"><i class='bx bxs-chart' ></i>
						<span class="hidden-minibar">Acc.Mgmt & FollowUp<span class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
				</li>
				<li v-if="loginusercheck(99)">
					<router-link to="/updatermn" @click.native="remove"><i class="fa fa-mobile"></i>
						<span class="hidden-minibar">Update R.M.N<span class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
				</li>
				<li v-if="loginusercheck(117)">
					<input type="checkbox" id="employee" />
					<label for="employee" style="margin-bottom: 0px"><i class="fa fa-users"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Team VsdigiWorld<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">3</span>
						</span></label>
					<ul class="submenu">
						<li v-if="loginusercheck(18)">
							<router-link to="/addemployee" @click.native="remove">
								<i class="fa fa-user"></i>
								<span class="hidden-minibar">Add Team VsdigiWorld</span>
							</router-link>
						</li>
						<li>
							<router-link to="/employee/0" @click.native="remove">
								<i class="fa fa-list"></i>
								<span class="hidden-minibar">Team VsdigiWorld</span>
							</router-link>
						</li>
						<li>
							<router-link to="/employee/1" @click.native="remove">
								<i class="fa fa-list"></i>
								<span class="hidden-minibar">Deactive Team VsdigiWorld</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(11) && loggedinuser.role > 1">
							<router-link to="/manageaccess" @click.native="remove"><i class="fa fa-cog"></i>
								<span class="hidden-minibar">Access</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(11) && loggedinuser.role > 1">
							<router-link to="/manageappaccess" @click.native="remove"><i class="fa fa-cog"></i>
								<span class="hidden-minibar">App Access</span>
							</router-link>
						</li>
						
					</ul>
				</li>
				<li v-if="loginusercheck(7)">
					<input type="checkbox" id="customer" />
					<label for="customer" style="margin-bottom: 0px"><i class="fa fa-users"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Customer<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span>
					</label>
					<ul class="submenu">
						<li v-if="loginusercheck(143)">
							<router-link to="/addcustomer" @click.native="remove"><i class="fa fa-user"></i>
								<span class="hidden-minibar">Add Customer</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(142)">
							<router-link to="/customer" @click.native="remove"><i class="fa fa-list"></i>
								<span class="hidden-minibar">View Customers </span>
							</router-link>
						</li>
						<li v-if="loginusercheck(142)">
							<router-link to="/professionmaster" @click.native="remove"><i class="fa fa-list"></i>
								<span class="hidden-minibar">Master</span>
							</router-link>
						</li>
					</ul>
				</li>

				<li v-if="loginusercheck(9)">
					<input type="checkbox" id="product" />
					<label for="product" style="margin-bottom: 0px"><i class="fa fa-linode"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Products & Price M.<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">6</span>
						</span></label>

					<ul class="submenu">
						<li>
							<router-link to="/addcategory" @click.native="remove"><i class="fa fa-sitemap"></i>
								<span class="hidden-minibar">Add Category</span>
							</router-link>
						</li>
						<li>
							<router-link to="/addsubcategory" @click.native="remove"><i class="fa fa-sitemap"></i>
								<span class="hidden-minibar">Add Product</span>
							</router-link>
						</li>
						<li>
							<router-link to="/addcompany" @click.native="remove"><i class="fa fa-sitemap"></i>
								<span class="hidden-minibar">Add Company</span>
							</router-link>
						</li>
						<!--     <li>
                  <router-link to="/category" @click.native="remove"
                    ><i class="fa fa-list"></i>
                    <span class="hidden-minibar">Category List</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/subcategory" @click.native="remove"
                    ><i class="fa fa-list"></i>
                    <span class="hidden-minibar">Sub Category List</span>
                  </router-link>
                </li>-->

						<li>
							<router-link to="/addproduct" @click.native="remove"><i class="fa fa-linode"></i>
								<span class="hidden-minibar">Add Model</span>
							</router-link>
						</li>
						<li>
							<router-link to="/editproduct" @click.native="remove"><i class="fa fa-linode"></i>
								<span class="hidden-minibar">Model Edit,View,Disabled</span>
							</router-link>
						</li>
						<li>
							<router-link to="/product" @click.native="remove"><i class="fa fa-list"></i>
								<span class="hidden-minibar">Price List </span>
							</router-link>
						</li>
						<li v-if="false">
							<router-link to="/addpricelist" @click.native="remove"><i class="fa fa-list"></i>
								<span class="hidden-minibar">Add PriceList </span>
							</router-link>
						</li>
					</ul>
				</li>
				<li v-if="loginusercheck(103)">
					<input type="checkbox" id="party" />
					<label for="party" style="margin-bottom: 0px"><i class="fa fa-list" style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Inventory<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span></label>

					<ul class="submenu">
						<li>
							<router-link to="/addparty" @click.native="remove">
								<i class="fa fa-user"></i>
								<span class="hidden-minibar">Add Party</span>
							</router-link>
						</li>
						<li>
							<router-link to="/party" @click.native="remove">
								<i class="fa fa-list"></i>
								<span class="hidden-minibar">View Party </span>
							</router-link>
						</li>
						<li>
							<router-link to="/addpurchase" @click.native="remove">
								<i class="fa fa-shopping-basket"></i>
								<span class="hidden-minibar">Add Purchase </span>
							</router-link>
						</li>
						<li>
							<router-link to="/purchasestock" @click.native="remove">
								<i class="fa fa-list"></i>
								<span class="hidden-minibar">View Purchase</span>
							</router-link>
						</li>
						<li>
							<router-link to="/partyledger" @click.native="remove">
								<i class="fa fa-list"></i>
								<span class="hidden-minibar">Party Ledger</span>
							</router-link>
						</li>
						<li>
							<router-link to="/stocktransfer" @click.native="remove"><i class='bx bxs-store-alt'></i>
								<span class="hidden-minibar">Transfer Stock</span>
							</router-link>
						</li>
						<li >
							<router-link to="/transferhistory" @click.native="remove"><i class='bx bxs-report'></i>
								<span class="hidden-minibar">Transfer Stock Report</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li>
					<router-link to="/stocks" @click.native="remove"><i class="fa fa-database"></i>
						<span class="hidden-minibar">Stocks<span class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
				</li>
				<li v-if="loginusercheck(102)">
					<input type="checkbox" id="Sales" />
					<label for="Sales" style="margin-bottom: 0px"><i class="fa fa-users" style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Sales Manager<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span>
					</label>

					<ul class="submenu">
						<li>
							<router-link to="/salesmanager" @click.native="remove"><i class="fa fa-book"></i>
								<span class="hidden-minibar">New Sales</span>
							</router-link>
						</li>
						<li>
							<router-link to="/viewsales" @click.native="remove"><i class="fa fa-book"></i>
								<span class="hidden-minibar">View Sales</span>
							</router-link>
						</li>
					</ul>
				</li>

				<li v-if="loginusercheck(105)">
					<router-link to="/emimanager" @click.native="remove"><i class="fa fa-book"></i>
						<span class="hidden-minibar">Emi Manager<span class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
				</li>

				<li v-if="loginusercheck(10)">
					<input type="checkbox" id="products" />
					<label for="products" style="margin-bottom: 0px"><i class="fa fa-slideshare"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Schemes<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">5</span>
						</span></label>

					<ul class="submenu">
						<li>
							<router-link to="/addschemes" @click.native="remove"><i class="fa fa-slideshare"></i>
								<span class="hidden-minibar">Add New Schemes</span>
							</router-link>
						</li>
						<li>
							<router-link to="/schemes" @click.native="remove"><i class="fa fa-list"></i>
								<span class="hidden-minibar">Schemes List </span>
							</router-link>
						</li>
						<li>
							<router-link to="/addongoingschemes" @click.native="remove"><i class="fa fa-slideshare"></i>
								<span class="hidden-minibar">New Scheme Sales </span>
							</router-link>
						</li>
						<li>
							<router-link to="/ongoingschemes" @click.native="remove"><i class="fa fa-list"></i>
								<span class="hidden-minibar">View Schemes List</span>
							</router-link>
						</li>
						<li>
							<router-link to="/defaulterongoingschemes" @click.native="remove"><i
									class="fa fa-slideshare"></i>
								<span class="hidden-minibar">Defaulter Schemes</span>
							</router-link>
						</li>
						<li>
							<router-link to="/scheduled" @click.native="remove"><i class="fa fa-list-alt"></i>
								<span class="hidden-minibar">Scheme Over Dues </span>
							</router-link>
						</li>
						<li>
							<router-link to="/refundschemes" @click.native="remove"><i class="fa fa-slideshare"></i>
								<span class="hidden-minibar">Refund</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li v-if="loginusercheck(97)">
					<input type="checkbox" id="crm" />
					<label for="crm" style="margin-bottom: 0px"><i class="fa fa-users" style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">C.R.M<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">3</span>
						</span>
					</label>

					<ul class="submenu">
						<li>
							<router-link to="/customerdata" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Customer Data</span>
							</router-link>
						</li>
						<li>
							<router-link to="/crmschedule" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Customers Scheduled</span>
							</router-link>
						</li>
						<li>
							<router-link to="/crmdispose" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Customers Dispose</span>
							</router-link>
						</li>
					</ul>
				</li>

				<li v-if="loginusercheck(96)">
					<input type="checkbox" id="complaint" />
					<label for="complaint" style="margin-bottom: 0px"><i class="fa fa-calendar"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Complaint<span
							class="badge bg-primary pull-right"
							style="background-color: #495b79 !important">3</span>
						</span></label>
					<ul class="submenu">
						<li v-if="loginusercheck(167)">
							<router-link to="/complaint" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">New Complaint</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(168)">
							<router-link to="/pendingcomplaint" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">View Pending</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(169)">
							<router-link to="/disposecomplaint" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">View Dispose</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li v-if="loginusercheck(188)">
					<input type="checkbox" id="cjso" />
					<label for="cjso" style="margin-bottom: 0px"><i class="fa fa-calendar"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Leads Manager<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">3</span>
						</span></label>
					<ul class="submenu">
						<li v-if="loginusercheck(189)">
							<router-link to="/newleads" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">New Leads</span>
							</router-link>
						</li>
						<!-- <li v-if="loginusercheck(190)">
							<router-link to="/pendingcjso" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">View Pending Leads</span>
							</router-link>
						</li> -->
						<li v-if="loginusercheck(191)">
							<router-link to="/scheduledleads" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">View Leads</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(192)">
							<router-link to="/disposecjso" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">View Dispose Leads</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li v-if="loginusercheck(178)"> 
					<input type="checkbox" id="order" />
					<label for="order" style="margin-bottom: 0px"><i class="fa fa-calendar"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Orders<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">3</span>
						</span></label>
					<ul class="submenu">
						<li v-if="loginusercheck(179)">
							<router-link to="/neworder" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Place New Order</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(180)">
							<router-link to="/vieworder" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">View Orders</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(181)">
							<router-link to="/dispatchorder" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">View Dispatched/Rejected</span>
							</router-link>
						</li>
					</ul>
				</li>

				<li v-if="loginusercheck(100)">
					<router-link to="/broadcast" @click.native="remove"><i class="fa fa-camera-retro"></i>
						<span class="hidden-minibar">Broadcast Manager<span class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
				</li>
				<li v-if="loginusercheck(14)">
					<input type="checkbox" id="advisors" />
					<label for="advisors" style="margin-bottom: 0px">
						<i class="fa fa-street-view" style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Advisors<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span>
					</label>

					<ul class="submenu">
						<li>
							<router-link to="/advisor" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Advisors Report</span>
							</router-link>
						</li>
						<li>
							<router-link to="/deactiveadvisor" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Deactive Advisors</span>
							</router-link>
						</li>
					</ul>
				</li>

				<li  v-if="loginusercheck(166)"> 
					<input type="checkbox" id="jobs" />
					<label for="jobs" style="margin-bottom: 0px">
						<i class="fa fa-street-view" style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">JOBS<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span>
					</label>

					<ul class="submenu">
						<li>
							<router-link to="/jobsform" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Job Application</span>
							</router-link>
						</li>
						<li >
							<router-link to="/jobs" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Job App. List</span>
							</router-link>
						</li>
						<li >
							<router-link to="/scheduledjobs" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Job App. Scheduled</span>
							</router-link>
						</li>
						
						<li>
							<router-link to="/rejectjobs" @click.native="remove"><i class="fa fa-street-view"></i>
								<span class="hidden-minibar">Rejected Job App. List</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li v-if="false">
					<router-link to="/customerdefaulter" @click.native="remove"><i class="fa fa-list-alt"></i>
						<span class="hidden-minibar">Defaulter Customers<span class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
				</li>

				<li v-if="loginusercheck(158)">
					<input type="checkbox" id="Accounts" />
					<label for="Accounts" style="margin-bottom: 0px"><i class="fa fa-book"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Accounts<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">4</span>
						</span></label>
					<ul class="submenu">
						<!-- <li>
							<router-link to="/closedaccount" @click.native="remove"><i class="fa fa-calculator"></i>
								<span class="hidden-minibar">Closed Accounts</span>
							</router-link>
						</li> -->
						<li v-if="loginusercheck(138)">
							<router-link to="/closedaccount/1" @click.native="remove"><i
									class="fa fa-calculator"></i>
								<span class="hidden-minibar">Clear Accounts</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(139)">
							<router-link to="/closedaccount/2" @click.native="remove"><i
									class="fa fa-calculator"></i>
								<span class="hidden-minibar">Defaulter Accounts</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(140)">
							<router-link to="/closedaccount/3" @click.native="remove"><i class="fa fa-users"></i>
								<span class="hidden-minibar">Legal Accounts</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(141)">
							<router-link to="/closedaccount/4" @click.native="remove"><i
									class="fa fa-users"></i>
								<span class="hidden-minibar">Concession Accounts</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li v-if="loginusercheck(153)">
					<input type="checkbox" id="verification" />
					<label for="verification" style="margin-bottom: 0px"><i class="fa fa-book"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Emi Conversation<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">1</span>
						</span></label>
					<ul class="submenu">
						<li v-if="loginusercheck(135)">
							<router-link to="/commentsverify/1" @click.native="remove"><i
									class="fa fa-users"></i>
								<span class="hidden-minibar">PTP Conversation Approval</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(136)">
							<router-link to="/commentsverify/0" @click.native="remove"><i
									class="fa fa-users"></i>
								<span class="hidden-minibar">PTP Conversation Report</span>
							</router-link>
						</li>
					</ul>
				</li>
				<li >
					<input type="checkbox" id="payments" />
					<label for="payments" style="margin-bottom: 0px"><i class="fa fa-credit-card"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Emi Payment Status<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span></label>
					<ul class="submenu">
						<li>
							<router-link to="/paymentstatus/emistatus" @click.native="remove"><i
									class="fa fa-rupee"></i>
								<span class="hidden-minibar">Received Emi Status</span>
							</router-link>
						</li>
						<li>
							<router-link to="/paymentstatus/emireport" @click.native="remove"><i
									class="fa fa-rupee"></i>
								<span class="hidden-minibar">Received Emi Report</span>
							</router-link>
						</li>
					</ul>
				</li>
				<!-- <li v-if="loginusercheck(137)">
					<input type="checkbox" id="daybook" />
					<label for="daybook" style="margin-bottom: 0px"><i class="fa fa-book"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Day Book<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span></label>

					<ul class="submenu">
						<li  v-if="loginusercheck(137)">
							<router-link to="/daybook" @click.native="remove"><i class="fa fa-users"></i>
								<span class="hidden-minibar">Day Book</span>
							</router-link>
						</li>
					</ul>
				</li> -->
				<li v-if="loginusercheck(137)">
					<input type="checkbox" id="expense" />
					<label for="expense" style="margin-bottom: 0px"><i class="fa fa-rupee"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Expenses<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span></label>

					<ul class="submenu">
						<li  v-if="loginusercheck(137)">
							<router-link to="/expense-head" @click.native="remove"><i class="fa fa-users"></i>
								<span class="hidden-minibar">Expense Head</span>
							</router-link>
						</li>
						<li>
							<router-link to="/expense-form" @click.native="remove"><i class="fa fa-users"></i>
								<span class="hidden-minibar">Expenses</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(11) && loggedinuser.role > 1">
							<router-link to="/salary-slip-form" @click.native="remove"><i class="fa fa-rupee"></i>
								<span class="hidden-minibar">Salary Slip Form</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(11) && loggedinuser.role > 1">
							<router-link to="/salary-slip" @click.native="remove"><i class="fa fa-rupee"></i>
								<span class="hidden-minibar">Salary Slip</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(11) && loggedinuser.role > 1">
							<router-link to="/employee-ledger" @click.native="remove"><i class="fa fa-rupee"></i>
								<span class="hidden-minibar">Employee Ledger</span>
							</router-link>
						</li>
						<li  v-if="loginusercheck(137)">
							<router-link to="/daybook" @click.native="remove"><i class="fa fa-users"></i>
								<span class="hidden-minibar">Day Book</span>
							</router-link>
						</li>
						<!-- <li>
							<router-link to="/expense-list" @click.native="remove"><i class="fa fa-users"></i>
								<span class="hidden-minibar">Expense List</span>
							</router-link>
						</li> -->
					</ul>
				</li>

				<li v-if="loginusercheck(101)">
					<input type="checkbox" id="Reports" />
					<label for="Reports" style="margin-bottom: 0px"><i class="fa fa-book"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Dues/Collections<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span>
					</label>
					<ul class="submenu">
						<li v-if="loginusercheck(154)">
							<router-link to="/emioverdues" @click.native="remove"><i class="fa fa-calculator"></i>
								<span class="hidden-minibar">Total Over Dues Report</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(161)">
							<router-link to="/overdues" @click.native="remove"><i class="fa fa-calculator"></i>
								<span class="hidden-minibar">Over Dues Report</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(156)">
							<router-link to="/dues" @click.native="remove"><i class="fa fa-calculator"></i>
								<span class="hidden-minibar">Dues Report</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(155)">
							<router-link to="/payment/emicollectionreport" @click.native="remove"><i class="fa fa-calculator"></i>
								<span class="hidden-minibar">EMI Collection Payment</span>
							</router-link>
						</li>
						<li v-if="loginusercheck(201)">
							<router-link to="/payment/schemecollectionreport" @click.native="remove"><i class="fa fa-calculator"></i>
								<span class="hidden-minibar">Scheme Collection Payment</span>
							</router-link>
						</li>
						
					</ul>
				</li>
				<!-- <li >
					<input type="checkbox" id="stocktransfer" />
					<label for="stocktransfer" style="margin-bottom: 0px"><i class="bx bxs-bar-chart-alt-2"
							style="padding-left: 13px"></i>
						<span class="hidden-minibar" style="padding-left: 10px">Stock Transfer<span
								class="badge bg-primary pull-right"
								style="background-color: #495b79 !important">2</span>
						</span>
					</label>
				</li> -->
				<li v-if="loginusercheck(144)">
					<router-link to="/smsreport" @click.native="remove">
						<i class='bx bx-envelope-open'></i>
						<span class="hidden-minibar"
						>SMS Report<span
							class="badge bg-primary pull-right"
							style="background-color: #495b79 !important"
							>1</span
						>
						</span>
					</router-link>
        		</li>
				<li v-if="loginusercheck(185)">
					<router-link to="/servicecenter" @click.native="remove">
						<i class='bx bxs-detail'></i>
						<span class="hidden-minibar" >Service Center<span
							class="badge bg-primary pull-right" style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
        		</li>
				<li v-if="loginusercheck(185)">
					<router-link to="/lottery" @click.native="remove">
						<i class='bx bxs-detail'></i>
						<span class="hidden-minibar">Lottery<span
							class="badge bg-primary pull-right" style="background-color: #495b79 !important">1</span>
						</span>
					</router-link>
        		</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Constants from "../../components/utilities/Constants.vue";
export default {
	mixins: [Constants],
	name: "navbar",
	computed: {
		...mapGetters(["loggedinuser"]),
	},
	methods: {
		remove() {
			this.$store.commit("assignviewno", 1);
		},
	},
};
</script>
